import React from 'react'

import './style.scss'
import  '../apis/youtube'




function Buttons(){

function ButtonKlick(event){
  document.querySelectorAll('.Button1').forEach(item=>{
    item.style.backgroundColor="white"
    item.style.color="black"
  })
 event.target.style.backgroundColor="black"
 event.target.style.color="white"
}
 

    
        return (
            
            <div className='Buttons' id="buttonPanel">
            <div className='Button1' id="alle">Alle</div>
            <div className='Button1'>Nachrichten</div>
            <div className='Button1'>Musik</div>
            <div className='Button1'>Gaming</div>
            <div className='Button1'>Live</div>
            <div className='Button1'>Fußball</div>
            <div className='Button1'>Basteln</div>
            <div className='Button1'>Natur</div>
            <div className='Button1'>Action-Adventures</div>
            <div className='Button1'>Reiseziele</div>
            <div className='Button1'>Kochen</div>
            <div className='Button1'>Kürzlich hochgeladen</div>
            <div className='Button1'>Neu für dich</div>
            

            
            
            
            
            
           

           </div>
);
  
}

export default Buttons
