import React from 'react'

import '../style.scss'
import  '../../apis/youtube'
import Menu from '../../header/Menu.jsx'
import  VideoListMid  from './VideoListMid.jsx';
import Footer from '../Footer'



  
function MainMid({data}){
 

   
    
        return (
            
            <div className="MainBody">
            <Menu/>
            <VideoListMid data={data}/>
            <Footer/>
             </div>
);
  
}

export default MainMid
