

export const videolink =(data,title,channel,desc,time,rand,image)=>{
    
    //initialState.id=data;
    window.localStorage.setItem(1,data)
    window.localStorage.setItem(2,title)
    window.localStorage.setItem(3,channel)
    window.localStorage.setItem(4,desc)
    window.localStorage.setItem(5,time)
    window.localStorage.setItem(6,rand)
    window.localStorage.setItem(7,image)
    
    return{
        type:'LINK',
        payload:{link:data,title:title, channel:channel, description:desc,time:time}
        
    };
}