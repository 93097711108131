import React, { useEffect } from 'react'
import '../body/style.scss'
import  '../apis/youtube'


import {videolink} from '../actions'
import {Link} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';




function Video(videoData){
  const items=useSelector((state)=>state.arrayids.idsArray)
 // const reel = useSelector((state)=>state.reels.value)
 const dispatch=useDispatch()
  let array=[]
  let myArrayIDs
  let myArray



  const handlePlay=()=>{
    var userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      
      
      document.getElementById("ad").pause()
  
      
    } 
    else{
     
        
        document.getElementById("ad").play()
      
      
    }
 
    

  }



  useEffect(()=>{


   myArrayIDs = sessionStorage.getItem('ids');
    myArray = JSON.parse(myArrayIDs);

  if(myArrayIDs==null || myArray==null){
    let ids=[]
    myArrayIDs = JSON.stringify(ids);
    sessionStorage.setItem('ids',myArrayIDs)
    myArray=JSON.parse(myArrayIDs);
  }

 




  myArray.forEach((item,index)=>{
    document.getElementById(item).style.opacity=0.3
    document.getElementById(item).style.pointerEvents="none"
 
  })



  const myArraynoIds = JSON.stringify(myArray);

  sessionStorage.setItem('ids',myArraynoIds)
  

  },[])

  return videoData.map(({snippet,id},index)=>{
    var rand=Math.floor(Math.random()*10000000);

 
    return(
        <div className="VideoPlayer" key={index} id={id.videoId}>
         
         <Link to='youtcheckbannervideo'> {<img className="Iframe" src={snippet.thumbnails.medium.url} onClick={()=>dispatch(videolink(id.videoId,snippet.title,snippet.channelTitle,snippet.description,snippet.publishedAt,rand,snippet.thumbnails.medium.url))}></img>} </Link>
        
          
         <div className='VideoPlayerDescr-Container'>
          <div className="Circl">
           <img className='Circl' src={snippet.thumbnails.medium.url} alt=""/>
          </div>
          <div className="TitleContainer">
          <div className="VideoName">{snippet.title}</div>
          <div className="ViewandChannel">{snippet.channelTitle}</div>
          <div className="ViewandChannel">{rand} Aufrufe</div>
          </div>
          </div>
        </div>
    )
  });
  
}


var paramsString = document.location.search; 
var searchParams = new URLSearchParams(paramsString);
    



export const VideoPlayer = ({ data }) => {

  return <>{Video(data)}</>;
};




