import React from 'react'

import './style.scss'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';



function Footer(){
   
    return(

        <div className='Foo'>

        <div className='IconsFoot'>
            <div className='FooterButtons'>
        <HomeOutlinedIcon fontSize='medium'/>
        <div style={{fontSize: '10px'}}>Start</div>

        </div>

        <div className='FooterButtons' >
        <ExploreOutlinedIcon fontSize='medium'/>
        <div style={{fontSize: '10px'}}>Shorts</div>
        </div>

        <AddCircleOutlineOutlinedIcon fontSize='large'/>

        <div className='FooterButtons'>
        <SubscriptionsOutlinedIcon fontSize='medium'/>
        <div style={{fontSize: '10px', }}>Abos</div>
        </div>

        <div className='FooterButtons'>
        <AccountCircleIcon fontSize='medium'/>
        
        <div style={{fontSize: '10px', right:'4%'}}>Sie</div>
        </div>

        </div>
           
        </div>
        
    );
}

 export default Footer;