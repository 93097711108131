
import '../style.scss'
import  '../../apis/youtube'

import VideoPlayerMid from './VideoPlayerMid';
import VideoList from './VideoList';



  
function MainBody({video,videolist}){
 
  
        return (
            
            <div className="Main">
            
              <VideoPlayerMid video={video} videolist={videolist}/>
              <VideoList  videolist={videolist}/>

            
             </div>
);
  
}

export default MainBody
