import React from 'react'

import './style.scss'
import  '../apis/youtube'
import Menu from '../header/Menu.jsx'
import  VideoList  from './VideoList.jsx';
import Footer from './Footer'




  
function Main({data}){
 
    window.localStorage.setItem("vidtype", "pre")
 
   
    
        return (
            
            <div className="MainBody">
            <Menu/>
            
            <VideoList data={data}/>
            <Footer/>
             </div>
);
  
}

export default Main
