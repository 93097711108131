import React,{useEffect, useState, Component, useRef} from 'react';
import '../body/style.scss'
import Logo from './youtcheck.svg'

//import Text from './text.json'

import Text from './data/text_short.json'
import Textchapter from './data/text_chapter.json'

import Accordion from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


//Banner
import Text2 from './data/text_details.json'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import historyLogo from './history-logo.png'


function BannerShort({closeBanner}) {

const [details, setDetails]=useState(true)

const BannerDetails=useRef(null)
const MainBanner=useRef(null)
let openDetails=false

const onRef=useRef(null)
const offRef=useRef(null)


useEffect(()=>{

  
},[])

const handleCloseOpenDetails=()=>{
  openDetails=!openDetails
  if(openDetails){
    BannerDetails.current.style.display="block"
    MainBanner.current.style.display="none"
  }
  if(!openDetails){
    BannerDetails.current.style.display="none"
    MainBanner.current.style.display="block"
  }
}

const handleActivateBtnOn=(e)=>{
  //setBtnActive(!btnActive)
  offRef.current.style.background="transparent"
e.target.style.background='#eeeded'
}
const handleActivateBtnOff=(e)=>{
  //setBtnActive(!btnActive)
  onRef.current.style.background="transparent"
  e.target.style.background='#eeeded'
}



  return (
    <>
   
    <div className='youtcheck-Banner-long' ref={MainBanner}> 
    <div className='youtcheck-Banner-long-head'>
      <div className='youtcheck-Banner-long-head-logo'><img src={Logo}/></div>
      <div className='youtcheck-Banner-long-head-login'>
      <svg className="OhAPlf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1c4.96 0 9 4.04 9 9 0 1.42-.34 2.76-.93 3.96-1.53-1.72-3.98-2.89-7.38-3.03A3.996 3.996 0 0016 9c0-2.21-1.79-4-4-4S8 6.79 8 9c0 1.97 1.43 3.6 3.31 3.93-3.4.14-5.85 1.31-7.38 3.03C3.34 14.76 3 13.42 3 12c0-4.96 4.04-9 9-9zM9 9c0-1.65 1.35-3 3-3s3 1.35 3 3-1.35 3-3 3-3-1.35-3-3zm3 12c-3.16 0-5.94-1.64-7.55-4.12C6.01 14.93 8.61 13.9 12 13.9c3.39 0 5.99 1.03 7.55 2.98C17.94 19.36 15.16 21 12 21z" fill='#065fd4'></path></svg>
      <span>Benutzer</span>
      </div>
      </div> 

      <div className='youtcheck-Banner-long-text-block'>

    

      <div className='youtcheck-Banner-long-text-block-text'>
        <div className='youtcheck-Banner-long-text-block-container'>
      <div className='youtcheck-Banner-long-text-block-heading'>Personalisierungseinstellungen & Cookies</div>

      <div className='youtcheck-Banner-accordion' style={{marginTop:'34px'}}>
    <Accordion style={{boxShadow: 'none'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{fontWeight:'bold',fontSize:'16px'}}
    >
        Youtcheck-Verlauf
        </AccordionSummary>
        <AccordionDetails>
        <div className='youtcheck-Banner-activate-history'>
        <div className='youtcheck-Banner-activate-history-text-img'>
          <div className='youtcheck-Banner-activate-history-text'>
            <div className='youtcheck-Banner-activate-history-text-head'>YouTcheck-Verlauf</div>
            <div className='youtcheck-Banner-activate-history-text-text'>Bei aktiviertem YouTcheck-Verlauf erhalten Sie beispielsweise Empfehlungen auf der YouTcheck-Startseite, die auf Ihren Aktivitäten wie etwa den Videos, die Sie sich ansehen, und Dingen, nach denen Sie suchen, basieren. Wenn diese Einstellung deaktiviert ist, werden YouTcheck-Funktionen, die zum Personalisieren Ihrer Nutzung Ihren Verlauf benötigen, deaktiviert.</div>
          </div>
          <div className='youtcheck-Banner-activate-history-img'><img src={historyLogo}/></div>
        </div>
        <div className='youtcheck-Banner-activate-history-btn'>
          <div className='youtcheck-historybtn' ref={offRef} onClick={handleActivateBtnOff}>Aus</div>
          <div className='youtcheck-historybtn' ref={onRef} onClick={handleActivateBtnOn}>Ein</div>
        </div>
      </div>
        </AccordionDetails>
        </Accordion>
      </div>

      <div className='youtcheck-Banner-text'>
      <MainText/>
      </div>

 
          

      <TextChapter/>
</div>


<div className='youtcheck-Banner-btns'>
        <div className='ad_klicks youtcheck-Banner-btn' onClick={handleCloseOpenDetails} data-click-tracking="youtcheck_short_mitBtn_optionen">Weitere Optionen</div>
        <div className='ad_klicks youtcheck-Banner-btn' data-click-tracking="youtcheck_short_mitBtn_ablehnen" onClick={closeBanner}>Alle ablehnen</div>
        <div className='ad_klicks youtcheck-Banner-btn' data-click-tracking="youtcheck_short_mitBtn_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
     </div>
      </div>

     
    

      </div>
      
    </div>
   


    {/*Banner*/}

 <div className='youtcheck-Banner-long BannerDetails' ref={BannerDetails}> 
    <div className='youtcheck-Banner-long-head'>
      <div className='youtcheck-Banner-long-head-logo'><img src={Logo}/></div>

      <div className='youtcheck-Banner-long-head-login'>
      <svg className="OhAPlf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 1c4.96 0 9 4.04 9 9 0 1.42-.34 2.76-.93 3.96-1.53-1.72-3.98-2.89-7.38-3.03A3.996 3.996 0 0016 9c0-2.21-1.79-4-4-4S8 6.79 8 9c0 1.97 1.43 3.6 3.31 3.93-3.4.14-5.85 1.31-7.38 3.03C3.34 14.76 3 13.42 3 12c0-4.96 4.04-9 9-9zM9 9c0-1.65 1.35-3 3-3s3 1.35 3 3-1.35 3-3 3-3-1.35-3-3zm3 12c-3.16 0-5.94-1.64-7.55-4.12C6.01 14.93 8.61 13.9 12 13.9c3.39 0 5.99 1.03 7.55 2.98C17.94 19.36 15.16 21 12 21z" fill='#065fd4'></path></svg>
      <span>Benutzer</span>
      </div>
      </div> 

      
      <div className='youtcheck-Banner-long-text-block'>
      
    

      <div className='youtcheck-Banner-long-text-block-text'>
      <div className='youtcheck-Banner-long-text-block-heading'>Personalisierungseinstellungen & Cookies</div>
      <div className='youtcheck-Banner-text'>{Text2.content[0].text}</div>

    <div className='youtcheck-banner-switcher'>
      <div className='ad_klicks youtcheck-options-switch'> <FormControlLabel  control={<Switch defaultChecked disabled={true} />} label={<div className='youtcheck-switch-label'>Zwingend notwendig</div>}/></div>    
     <div className='ad_klicks youtcheck-options-switch' data-click-tracking="youtcheck_short_mitBtn_optionen_komfort_cookie"> <FormControlLabel  control={<Switch />} label={<div className='youtcheck-switch-label'>Komfort Cookies</div>}/></div>
     <div className='ad_klicks youtcheck-options-switch' data-click-tracking="youtcheck_short_mitBtn_optionen_marketing"> <FormControlLabel  control={<Switch/>} label={<div className='youtcheck-switch-label'>Marketing Cookies</div>}/></div>
     </div>  



<TextChapter/>


<div className='youtcheck-Banner-btns'>
        
        <div className='youtcheck-Banner-btn btnBack' onClick={handleCloseOpenDetails}>Zurück</div>
        <div className='ad_klicks youtcheck-Banner-btn' data-click-tracking="youtcheck_short_mitBtn_optionen_alle_ablehnen" onClick={closeBanner}>Alle ablehnen</div>
        <div className='ad_klicks youtcheck-Banner-btn Bannerbtnaccept' data-click-tracking="youtcheck_short_mitBtn_optionen_alle_akzeptieren" onClick={closeBanner}>Alle erlauben</div>
        <div className='ad_klicks youtcheck-Banner-btn' data-click-tracking="youtcheck_short_mitBtn_optionen_auswahl_akzeptieren" onClick={closeBanner}>Auswahl erlauben</div>
     </div>
      </div>

     
    

      </div>
      
    </div>


    </>
  );



  function TextChapter(){
    return Textchapter.content.map((item,index)=>{
      return(
        <div className='youtcheck-Banner-accordion'>
    <Accordion style={{boxShadow: 'none'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{fontWeight:'bold',fontSize:'16px'}}
    >
        {item.heading}
        </AccordionSummary>
        <AccordionDetails>
        <div className='reweka-banner-short-head-text'>
          {item.text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        </AccordionDetails>
        </Accordion>
      </div>
      )
    })
  }


  function MainText(){
    return Text.content.map((item,index)=>{
      return <div key={index}>
      {item.text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
      </div>
    })
  }




  function TextChapter(){
    return Textchapter.content.map((item,index)=>{
      return(
        <div key={index} className='youtcheck-Banner-accordion'>
    <Accordion style={{boxShadow: 'none'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{fontWeight:'bold',fontSize:'16px'}}
    >
        {item.heading}
        </AccordionSummary>
        <AccordionDetails>
        <div className='reweka-banner-short-head-text'>
          {item.text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        </AccordionDetails>
        </Accordion>
      </div>
      )
    })
  }


}

/*
<div className='youtcheck-Banner-long-headText'>Optionale Cookies und Technologien erlauben?</div>
      <div className='youtcheck-Banner-long-text_btns'>
        <div className='youtcheck-Banner-long-text'>
          <TextLong/>
        </div>
        <div className='youtcheck-Banner-long-btns'>
          <div className='ad_klicks youtcheck-btn-options' data-click-tracking="youtcheck_long_mitBtn_optionen" onClick={handleCloseOpenDetails}>Mehr Optionen</div>
          <div className='ad_klicks youtcheck-btn' data-click-tracking="youtcheck_long_mitBtn_ablehnen">Alle Ablehnen</div>
          <div className='ad_klicks youtcheck-btn' data-click-tracking="youtcheck_long_mitBtn_akzeptieren">Alle erlauben</div>
        </div>
      </div>
*/




export default BannerShort;
