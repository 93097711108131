import React from 'react';
import './App.scss';

import MainBody from './banner/body_selectedVideo/MainBody';


function SelectedVideo({videolist}){

  
  return(
    <div id="bodyVideo">
        <MainBody videolist={videolist}/>
    </div>
  )
}

export default SelectedVideo