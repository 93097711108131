import React from 'react';
import './App.scss';

import MainBody from './body_selectedVideo/mid/MainBody';


function SelectedVideoMid({data,videolist}){

  return(
    <div id="bodyVideo">
        <MainBody video={data} videolist={videolist}/>
    </div>
  )
}

export default SelectedVideoMid