import React,{useEffect, useState, Component, useRef} from 'react';
import '../../body_selectedVideo/style.scss'
import  '../../apis/youtube'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import './script.js'
import Error from './pic/error2.svg'
import Loadi from './pic/loading.gif'
import { useSelector,useDispatch } from 'react-redux';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import { useNavigate,Link  } from 'react-router-dom';
import Dialog from './Dialog.jsx'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



  var paramsString = document.location.search; 
  var searchParams = new URLSearchParams(paramsString);

  var type="pre"
  var skipp=searchParams.get("s")
  var current_time_interval
  var status_interval
   var playerX
   var VideoEnd
   var AdEnded=false
   let WerbungVideoAd
 var ids_count=sessionStorage.getItem('ids_count') || 0
  let countwdownInterval 
  let emergency_ids=[]
  let like=539
  var duration
 

  let noIds=[]
  var userAgent = window.navigator.userAgent;
  var time=0;
  var videoDuration=0

  let timeafterskipbtn=0
  let timeafterskipvid=0



  function VideoPlayer({videolist}){
   
    const [isplayed, setPlay]=React.useState(true)
    const [value, setValue] = React.useState(1);
    const [countdown, setCountdown] = React.useState(5);
    const [mobile, setMobile]=React.useState(false)
    const items=useSelector(state=>state.array)
    const dispatch=useDispatch()
    emergency_ids=[]

    const [dialogg, setDialogg]=React.useState(false)
    
    const imageRef=useRef()
 
    const history = useNavigate();

   for(let key in videolist){

    emergency_ids.push(videolist[key].id.videoId)
   }


    const videoRef=useRef(null)
     
    
   var isplay=true


   const handlePlayPause = ()=>{
    setPlay(!isplayed)
    
    const customVideo=document.getElementById("ad");

    isplayed ? customVideo.play():customVideo.pause()
    
    
}

 const handleCountdown=(newValue)=>{
  setCountdown(newValue)
 }


 function showSkippBnt(){
  var countdown=6
 
  var btnSkipp=document.getElementById("btnskipp");
  var videoTag=document.getElementById("ad")
  btnSkipp.style.display="flex"
  btnSkipp.style.visibility="visible"

  setTimeout(()=>{btnSkipp.style.opacity="0.9"},1000)
  countwdownInterval=setInterval(function(){
    countdown=countdown-1
    handleCountdown(countdown)

  if(videoTag.paused==true)
  clearInterval(countwdownInterval);
  if(countdown==0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  videoTag.onpause=function(){
  clearInterval(countwdownInterval);
  }
  videoTag.onplay=function(){
  countwdownInterval=setInterval(function(){countdown=countdown-1;
    handleCountdown(countdown)

  if(countdown==0 || countdown<0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  },1000)
  }
  },1000)
  }



  


  const handleChangeVolumemore = (event, newValue)=>{
    const customVideo=document.getElementById("ad");
   
    customVideo.muted=false;
     setValue(newValue);


   customVideo.volume=newValue
   

}


const handleUnmute = ()=>{
  const customVideo=document.getElementById("ad");

  customVideo.muted=false;
  
 customVideo.volume=0.2
 setValue(0.2)


}

var player=null




const PlayPause = ()=>{
  //var advr = document.getElementById("ad")
 
 
 
 if( playerX.getPlayerState()==1){
  playerX.pauseVideo()
 }
 else if(playerX.getPlayerState()==2){
  playerX.playVideo()
 }




}


 
   
    var youTube=document.getElementById("YouTubeVideo")

    function initYouTubeAPI() {
      if (window['YT'] && window['YT'].Player) {
        createPlayer();
      } else {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
        window['onYouTubeIframeAPIReady'] = function() {
          createPlayer(); 
        };
      }
     
    }
    

    
function createPlayer() {

  var YT=window['YT']

  player = new window['YT'].Player('player', {
    videoId: `${window.localStorage.getItem(1)}`,
    playerVars:{rel:0, autoplay: 1, modestbranding:1, fs:0, playsinline:1},
    events: {
      'onStateChange': onPlayerStateChange,
       'onReady':onPlayerReady,
     
    }

    
  });

  playerX=player
  

    function onPlayerReady(event) {
   duration=playerX.getDuration()
   playerX.playVideo()
    var index=0  
    var main_video_player=document.getElementById("videoplayer")
    var forwardWindow=document.getElementById("forwardWindow")
    document.getElementById("videolist").style.display="block"
    var timerInterval
    var timer=3

  


    
    if(event.target.getDuration()==0)
    {
      if(ids_count<emergency_ids.length-1){
        ids_count++
        sessionStorage.setItem('ids_count', ids_count)
        
        const myArrayIDs = sessionStorage.getItem('ids');
        const myArray = JSON.parse(myArrayIDs);

        myArray.forEach((item,index)=>{
          noIds.push(item)
        })

        noIds.push(localStorage.getItem(1))
        const myArraynoIds = JSON.stringify(noIds);

        sessionStorage.setItem('ids',myArraynoIds)
       
       
      }
      else{
        ids_count=1
        sessionStorage.setItem('ids_count', ids_count)

        const myArrayIDs = sessionStorage.getItem('ids');
        const myArray = JSON.parse(myArrayIDs);

        myArray.forEach((item,index)=>{
          noIds.push(item)
        })

        noIds.push(localStorage.getItem(1))
        const myArraynoIds = JSON.stringify(noIds);

        sessionStorage.setItem('ids',myArraynoIds)
        
   
      }
      
   
      
      forwardWindow.style.display="block"
      document.getElementById("videolist").style.display="none"
      main_video_player.style.pointerEvents='none'
    
      main_video_player.style.zIndex="1"
      main_video_player.style.position="absolute"
      document.getElementById("player").style.filter="blur(2px)"
      document.getElementById("info").style.filter="blur(2px)"
      document.getElementById("infochannel").style.filter="blur(2px)"
      timerInterval=setInterval(()=>{
        timer--
     
      
      },1000)
     
      
       setTimeout(() => {
        clearInterval(timerInterval)
       
        document.getElementById("btnskipp").style.display="none"
      
      }, 3000);
      


      
    
    }

    

    }

      function onPlayerStateChange(event) {
    
        if (event.data == YT.PlayerState.ENDED) {
         VideoEnd=event.data
        }
      }


 }
  
  
  async function initiate(){
     initYouTubeAPI();
     
    //like=Math.floor(Math.random()*1000);
  }
   
   const setAd=()=>{

initiate()


  }

  
    
    
    var paramsString = document.location.search; 
    var searchParams = new URLSearchParams(paramsString);
    

  

    let dialog=document.getElementById("iosDialog")



 

    useEffect(()=>{

      

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });

      window.matchMedia("(orientation: landscape)").addEventListener("change",e=>{
        const land=e.matches
      
        if(land){
          document.getElementById("videoplayer").style.marginTop=0
          document.getElementById("ad").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("player").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("shield").style.height=parseInt(document.documentElement.clientHeight-57)+"px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','landscape')
          document.body.style.overflow="hidden"
         
        }
        else{
          document.getElementById("ad").style.height="270px"
          document.getElementById("player").style.height="270px"
          document.getElementById("shield").style.height="212px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','portrait')
          document.body.style.overflow="auto"
        }
      })
    
      
      //document.getElementById("menu").style.display="none"
      //document.getElementById("buttonPanel").style.display="none"
     {setAd()}
     
     if(window.innerWidth<=480){
      document.getElementById('nav').style.display="none"
     }
     else{
      document.getElementById('nav').style.display="block"
     }
    


    
     if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
    
     setDialogg(true)
       document.body.style.overflow="hidden"
 
  
     
   } 

    
    


  },[])
 

      return(
          
 
          
          <div className="Videoplayer" id="videoplayer" data-click-tracking="portrait">

           {dialogg && <Dialog onClick={iosStartVideo}/>}  

         <div className='ForwardWindow' id="forwardWindow">
        <img className='ForwardWindowImg' src={Error}/>
        <h2 className='ForwardWindowText' style={{color:'white'}}>Das Video ist nicht verfügbar. <span style={{color:'red'}}> Klicken Sie auf den Button. </span> Sie werden zürück zur Videoauswahl weitergeleitet </h2>
      
       <Link style={{textDecoration:'none'}} to="/youtcheck/pre/?name=1_yRnF-DyBE3dTCs3c4Iei_FfeJT9AsJz&thema=kuchen&s=1">{<div className='Back'>Zurück zur Auswahl</div>}</Link> 

       
         </div>
       

            <div className="Overlay" onClick={PlayPause} id="shield">
            
          <div className='Adshell' id="adshell">  
         
      

          <img className='IosLoadImg' id="iosloadimg" src={Loadi}/>
          
    
    

         

          </div> 


          </div>


        
          <div className="OverlayCase">
          <div className="Video" id="player"></div>
          <div className='Coverlogo'></div>
          </div>
          
          <div className="BtnSkipp" id="btnskipp" variant='contained'>
          <div className='Countdown'>{countdown}</div>
          <img className='CountdownImg' src={window.localStorage.getItem(7)}/>
          </div>
          
          <div className="VideoInfo" id="info">

          <p className="VideoTitle" id="title" >{window.localStorage.getItem(2)}</p>

          </div>

      
        

          <div className="InfoaboutChannel" id="infochannel">
          <div className='ChannelPanel'>
            <div className='ImgAndAbo'>

              <div className='Container-mobile'>
            
            <div className='Channel-container'><img className="Channel" src={window.localStorage.getItem(7)}></img></div>
           
            <div className="p1">
            <div className='text-container'>{window.localStorage.getItem(3)}</div>
            </div>
            
            </div>
 
            <div className='AboDiv'>

             <div className='AboBtn'>Abonnieren</div>
            
             </div>

             </div>

             <div className='ButtonsPanel'>
             <div className='Btn '><div id="btnlike"><ThumbUpAltOutlinedIcon   sx={{fontSize:18}}> </ThumbUpAltOutlinedIcon> <p style={{marginLeft: "10px"}}>{like}</p></div>
             <div><ThumbDownOffAltOutlinedIcon id="btndislike" style={{fontSize:18}}> </ThumbDownOffAltOutlinedIcon></div></div>
            <div className='Teilen'><ReplyIcon id="btnteilen" style={{transform:'scaleX(-1)',fontSize:18}}></ReplyIcon><p>Teilen</p></div>
           
            <div className='Download'><VerticalAlignBottomOutlinedIcon sx={{marginLeft:'16px',fontSize:18}}></VerticalAlignBottomOutlinedIcon><p>Herunterladen</p></div>
            <div className='Clip'><ContentCutOutlinedIcon sx={{marginLeft:'7px',fontSize:18}}></ContentCutOutlinedIcon><p>Clip</p></div>
            <div className='Save'><QueueOutlinedIcon sx={{marginLeft:'12px',fontSize:18}}></QueueOutlinedIcon><p>Speichern</p></div>
            <div className='Punkte'><p>...</p></div>

            </div>
            </div>
             
                <div className="p3">
                  <div id="Comments">Kommentare</div>
                <div className='Descr'>{window.localStorage.getItem(4)} <span>Mehr</span></div>
                <div className='Comm'><AccountCircleIcon  sx={{fontSize:30, color:'#4487df'}}/> <div id="addCommText">Kommentar hinzufügen</div> </div>
                </div>
              
               
                
            
           
            
            </div>
          


            </div>
      )
      

      function iosStartVideo(){

        setDialogg(false)
        playerX.playVideo()
       
      }
    
  }



 


  export default VideoPlayer