import {React,useEffect,useRef,useState} from 'react'
import '../body/style.scss'
import  '../apis/youtube'
import BannerLong from './BannerLong.jsx';
import BannerLong2 from './BannerLong2.jsx';
import BannerShort from './BannerShort.jsx';
import BannerShort2 from './BannerShort2.jsx';
import  VideoList  from './VideoList.jsx';
import Footer from './Footer'
import Menu from '../header/Menu.jsx';




  
function Main({data}){
 // const [click,setClick]=useState(adtype==='true')
    //window.localStorage.setItem("vidtype", "pre")
    const Mainblock=useRef(null)
    

    const [showBanner, setShowBanner]=useState(sessionStorage.getItem('bannerState')=='true') //fasle für test
    const [interval, setIntervalforTimer]=useState(null)

    //sessionStorage.setItem('key', 'value')
    

    let urlParam=document.location.search;
    let searchParams=new URLSearchParams(urlParam)
    let banner=searchParams.get("b")
    const count=useRef(0)


    function BannerTimer(timer) {

      if (timer==true) {
    
        const interval = setInterval(() => {
        
          count.current+=1
        
     
         }, 1000);
         setIntervalforTimer(interval)
       }
     else{
     
       
       clearInterval(interval);
       //setIntervalforTimer(null)
       //eslint-disable-next-line
       sendResultFieldData({1:String(count.current)})
     }
    
    
   
   
  }


    const handleCloseBanner=()=>{
      setShowBanner(false)
      sessionStorage.setItem('bannerState', false)
      Mainblock.current.style.display="flex"
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      BannerTimer(false)
    }
   
    useEffect(()=>{
       //Mainblock.current.style.display="none"
       BannerTimer(true)
    },[])
    
        return (
            <>
               {showBanner && <SelectBanner type={banner}/>}
            <div className="MainBody" ref={Mainblock}>
            <Menu/>
              {!showBanner && <VideoList data={data}/>} 
            <Footer/>
             </div>
             </>
);





function SelectBanner(props){
    const type=props.type
      switch(type){
        case "1":
        return<BannerLong closeBanner={handleCloseBanner}/>
       
        case "2":
          return<BannerLong2 closeBanner={handleCloseBanner}/>
  
        case "3":
          return<BannerShort closeBanner={handleCloseBanner}/>

        case "4":
          return<BannerShort2 closeBanner={handleCloseBanner}/>
  
       
      }
    }
  
}

export default Main
