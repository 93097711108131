import React from 'react'

import '../body/style.scss'
import  '../apis/youtube'
import Buttons from '../header/Buttons.jsx';
import { VideoPlayer } from './VideoPlayer.jsx';




function VideoList({data}){
   
    return(

        <div className="VideoList"  style={{textAlign:'center'}}>

            <Buttons/>
           <VideoPlayer data={data}/>
        
           
        </div>
        
    );
}

 export default VideoList;