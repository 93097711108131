import React from 'react'

import './styles.scss'

import Drawer from './Drawer'



 
  export const YoutubeNav=(props) =>{
   
    
        return (
            
            <div className="Body" id="nav">
            
            <Drawer/>
          

        
          
            
            

             </div>
);
  
}
