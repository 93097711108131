

import '../../body_selectedVideo/style.scss'
import  '../../apis/youtube'

import VideoPlayer from './VideoPlayer';
import VideoList from './VideoList';



  
function MainBody({video,videolist}){
 
  
        return (
            
            <div className="Main">
        
              <VideoPlayer video={video} videolist={videolist}/>
              
              <VideoList  videolist={videolist}/>

            
             </div>
);
  
}

export default MainBody
