import React from 'react'

import './style.scss'






  
function Start({data}){
  
  
        return (
            
            <div className="Impressum">

  
               
               <div className='ImpressumLinks'>
               <div className='p'>Annalect YouTcheck</div>
               <div className='textp2'><a href="/youtcheck/demo/">Demo</a></div>
              {/*<div className='textp2'><a href="/youtcheck/admin/">Admin</a></div>*/}
        

               </div>
               <div className='FootText'>© 2024 annalect. A brand of Omnicom Media Group. All Rights reserved. <a href="http://www.annalect.de/impressum">Impressum</a></div>
             </div>
);
  
}

export default Start
