

import '../style.scss'
import  '../../apis/youtube'

import VideoPlayerAfter from './VideoPlayerAfter';
import VideoList from './VideoList';



  
function MainBody({video,videolist}){
 
  
        return (
            
            <div className="Main">
            
              <VideoPlayerAfter video={video} videolist={videolist}/>
              <VideoList  videolist={videolist}/>

            
             </div>
);
  
}

export default MainBody
