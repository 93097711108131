import React from 'react'

import './style.scss'

import { useSelector,useDispatch } from 'react-redux';




  
function Impressum({data}){
  const dispatch=useDispatch()
  const items=useSelector((state)=>state.arrayids.idsArray)
  let ids=[]
  const myArrayIDs = JSON.stringify(ids);
  sessionStorage.setItem('ids',myArrayIDs)



  function operatingSytem() { 
    var userAgent = window.navigator.userAgent;

    if (/Windows/.test(userAgent)) {

    } else if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      alert("Der Besucher verwendet ein Macintosh-Betriebssystem.");
    } else if (/Linux/.test(userAgent)) {
     
    } else {

    }
} 

   
    
        return (
            
            <div className="Impressum">

             
               
               <div className='ImpressumLinks'>
               <div className='p'>Annalect YouTcheck Demo</div>
           
               <div className='textp2'><a href="/youtcheck/pre/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=1">YouTcheck Pre Roll (Demo)</a></div>
               <div className='textp2' id="prenon"><a href="/youtcheck/pre/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=0">YouTcheck Pre Roll non skippable (Demo)</a></div>
              
               <div className='textp3'><a href="/youtcheck/mid/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=1">YouTcheck Middle Roll (Demo)</a></div>
               <div className='textp3' id="midnon"><a href="/youtcheck/mid/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=0">YouTcheck Middle Roll non skippable (Demo)</a></div>
               <div className='textp4'><a href="/youtcheck/after/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=1">YouTcheck After Roll (Demo)</a></div>
               <div className='textp4' id="afternon"><a href="/youtcheck/after/?name=1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot&s=0">YouTcheck After Roll non skippable (Demo)</a></div>
             

               </div>
               <div className='FootText'>© 2024 annalect. A brand of Omnicom Media Group. All Rights reserved. <a href="http://www.annalect.de/impressum">Impressum</a></div>
             </div>
);
  
}

export default Impressum
