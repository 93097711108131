import React,{useEffect} from 'react';
import './App.scss';
import ErrorPict from './pict/error.svg'


function Error({errr}){

  function ErrorCode(props){
const err_code=props.code

if(err_code){
return(
  <>
  <div className='ErrorBody'>
    <div className='ErrImgandText'>

      <div className='ErrImgDiv'>
        <img src={ErrorPict}/>
      </div>

      <div className='ErrTextDiv'>
         Sorry, der Server ist momentan nicht erreichbar. Versuchen Sie es später noch einmal
         Code: {errr}
      </div>

      </div>
  </div>
  </>
)
}

  }


  return(
    <div className='ErrorPage'>
      <ErrorCode code={errr}/>
    </div>
  )
}

export default Error