import React,{useEffect, useState, Component, useRef} from 'react';

  
function Dialog(props){


  function operatingSytem() { 
    var userAgent = window.navigator.userAgent;

    if (/Windows/.test(userAgent)) {

    } else if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      alert("Der Besucher verwendet ein Macintosh-Betriebssystem.");
    } else if (/Linux/.test(userAgent)) {
      
    } else {

    }
} 




   
    
        return (
            
        <div className='banner-Dialog' id="banner-iosDialog">

         

          <div id="textandbuttonbox">
          
          <div id="klicktext">Klicken Sie auf "Weiter"</div>

          <div id='weiterbtn' onClick={props.onClick}>Weiter</div>
          </div>

        </div>            
             

);
  
}

export default Dialog
  