import { createSlice } from '@reduxjs/toolkit'

const initialState={
    idsArray:[],
}

export const arrayids = createSlice({
    name: 'ids',
    initialState,
    reducers: {
      addItem: (state,action) => {
      
        state.idsArray.push(action.payload)
      },
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { addItem } = arrayids.actions
  
  export default arrayids.reducer