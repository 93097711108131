import {Link, Outlet} from 'react-router-dom'
import { YoutubeNav } from '../header/YoutubeNav'



const Layout=()=>{
  
    return (
        <>
          
            <YoutubeNav/>

            <Outlet/>
        </>
    )
}


export {Layout}