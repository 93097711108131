import React, { useRef, useEffect } from 'react'

import '../style.scss'
import  '../../apis/youtube'




  function GetVideos(videolist){

    
    return videolist.map(({snippet,id},index)=>{
      var rand=Math.floor(Math.random()*10000000);
     
      return(
          <div className="VideoContainer" key={index}>
           
           {<img id="videoListVideo" className="VideosInList" src={snippet.thumbnails.medium.url}></img>} 

            <div className="TitleofVideo">
            <div id="title">{snippet.title}</div>
            <div className="channelTitleUndAufrufe">{snippet.channelTitle}</div>
            <div className="channelTitleUndAufrufe">{rand} Aufrufe</div>
            </div>
          </div>
      )
    });
  }


  function VideoList({videolist}){

    //
    

      return(
          <div className="Videolist" id="videolist">
             <>{GetVideos(videolist)}</>
          </div>
      )
  }

  export default VideoList