import React from 'react';
import './App.scss';

import MainBody from './body_selectedVideo/after/MainBody';


function SelectedVideoAfter({data,videolist}){

  return(
    <div id="bodyVideo">
        <MainBody video={data} videolist={videolist}/>
    </div>
  )
}

export default SelectedVideoAfter