import React from 'react'

import '../style.scss'
import  '../../apis/youtube'
import Buttons from '../../header/Buttons.jsx'
import { VideoPlayer } from './VideoPlayerAfter.jsx';




function VideoList({data}){
   
    return(

        <div className="VideoList">
            <Buttons/>
           <VideoPlayer data={data}/>
        
           
        </div>
        
    );
}

 export default VideoList;