import React from 'react'

import '../style.scss'
import  '../../apis/youtube'
import Menu from '../../header/Menu.jsx'
import  VideoList  from './VideoListAfter.jsx';
import Footer from '../Footer'



  
function MainAfter({data}){
 

   
    
        return (
            
            <div className="MainBody">
               <Menu/>
            <VideoList data={data}/>
            <Footer/>
             </div>
);
  
}

export default MainAfter
