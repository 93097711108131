import React,{useEffect, useState, Component, useRef} from 'react';

import '../style.scss'
import  '../../apis/youtube'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplyIcon from '@mui/icons-material/Reply';

import '../script.js'
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CLoseIconSvg from '../pic/close.svg'
import Error from '../pic/error2.svg'
import Loadi from '../pic/loading.gif'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import Crop32Icon from '@mui/icons-material/Crop32';


import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';


import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import { useNavigate,Link  } from 'react-router-dom';
import Dialog from '../../body/Dialog';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VideoLong from '../../ads/1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot.mp4'



 

  var paramsString = document.location.search; 
  var searchParams = new URLSearchParams(paramsString);
  //var type=searchParams.get("type");
  var type="mid"
  var skipp=searchParams.get("s")
  var current_time_interval
  var status_interval
   var playerX
   var VideoEnd
   var AdEnded=false
 var ids_count=sessionStorage.getItem('ids_count') || 0
  let countwdownInterval 
  let emergency_ids=[]
  let like
  let videoOn=false
  var duration

  let testbool=true
  let noIds=[]
  var userAgent = window.navigator.userAgent;

  var time=0;
  var videoDuration=0
  var halfDuration=0
  let timeafterskipbtn=0
  let timeafterskipvid=0


  function VideoPlayerMid({videolist}){
   
    const [isplayed, setPlay]=React.useState(true)
    const [value, setValue] = React.useState(1);
    const [countdown, setCountdown] = React.useState(5);
    const [mobile, setMobile]=React.useState(false)
    
    emergency_ids=[]

    const imageRef=useRef()


   for(let key in videolist){

    emergency_ids.push(videolist[key].id.videoId)
   }


    
     
    
   var isplay=true


   const handlePlayPause = ()=>{
    setPlay(!isplayed)
    
    const customVideo=document.getElementById("ad");

    isplayed ? customVideo.play():customVideo.pause()
    
    
}

 const handleCountdown=(newValue)=>{
  setCountdown(newValue)
 }


 function showSkippBnt(){
  var countdown=6
 // var btnSkippImg=document.createElement('img')
 // btnSkippImg.src=window.localStorage.getItem(7)
  var btnSkipp=document.getElementById("btnskipp");
  var videoTag=document.getElementById("ad")
  btnSkipp.style.display="flex"
  btnSkipp.style.visibility="visible"
 // btnSkipp.append(btnSkippImg)
  setTimeout(()=>{btnSkipp.style.opacity="0.9"},1000)
  countwdownInterval=setInterval(function(){
    countdown=countdown-1
    handleCountdown(countdown)
 // countdown=countdown-1; btnSkipp.getElementsByTagName("span")[0].textContent=countdown;
  if(videoTag.paused==true)
  clearInterval(countwdownInterval);
  if(countdown==0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  videoTag.onpause=function(){
  clearInterval(countwdownInterval);
  }
  videoTag.onplay=function(){
  countwdownInterval=setInterval(function(){countdown=countdown-1;
    handleCountdown(countdown)
//  btnSkipp.getElementsByTagName("span")[0].textContent=countdown;
  if(countdown==0 || countdown<0){
  clearInterval(countwdownInterval);
  btnSkipp.className="BtnSkippEnd"
  btnSkipp.innerText="Werbung überspringen"
  btnSkipp.style.pointerEvents="auto"
  }
  },1000)
  }
  },1000)
  }



  



  
  const handleChangeVolumemore = (event, newValue)=>{
    const customVideo=document.getElementById("ad");
   
    customVideo.muted=false;
     setValue(newValue);


   customVideo.volume=newValue
   

}


const handleUnmute = ()=>{
  const customVideo=document.getElementById("ad");
  /* 
 if(customVideo.paused==true){
  customVideo.pause()
 }
 else if(customVideo.paused==false){
  customVideo.play()
 }
 */
  customVideo.muted=false;
  
 customVideo.volume=0.2
 setValue(0.2)

//document.getElementById("unmute").style.display="none"
}

var player




const PlayPause = ()=>{
  var advr = document.getElementById("ad")
 
 
 
 if(!videoOn && playerX.getPlayerState()==1){
  playerX.pauseVideo()
 }
 else if(!videoOn && playerX.getPlayerState()==2){
  playerX.playVideo()
 }

 if(advr.paused==true && AdEnded==false && videoOn){
  advr.play()
  setPlay(!isplayed)
  
 }

 else if(advr.paused==false && AdEnded==false && videoOn){
  advr.pause()
  setPlay(!isplayed)
 }


}



   
var youTube=document.getElementById("YouTubeVideo")

function initYouTubeAPI() {
  if (window['YT'] && window['YT'].Player) {
    createPlayer();
  } else {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = function() {
      createPlayer(); 
    };
  }
}



function createPlayer() {

var YT=window['YT']

player = new window['YT'].Player('player', {
videoId: `${window.localStorage.getItem(1)}`,
playerVars:{rel:0, autoplay: 1, modestbranding:1, fs:0, playsinline:1},
events: {
  'onStateChange': onPlayerStateChange,
   'onReady':onPlayerReady,
 
}


});




function onPlayerReady(event) {
  playerX=player
  duration=playerX.getDuration()
   halfDuration=duration/2
  
   var index=0  
   var main_video_player=document.getElementById("videoplayer")
   var forwardWindow=document.getElementById("forwardWindow")
   document.getElementById("videolist").style.display="block"
   var timerInterval
   var timer=3


// if(testbool==true) 


if(event.target.getDuration()==0)
{
  if(ids_count<emergency_ids.length-1){
    ids_count++
    sessionStorage.setItem('ids_count', ids_count)
    
    const myArrayIDs = sessionStorage.getItem('ids');
    const myArray = JSON.parse(myArrayIDs);

    myArray.forEach((item,index)=>{
      noIds.push(item)
    })

    noIds.push(localStorage.getItem(1))
    const myArraynoIds = JSON.stringify(noIds);

    sessionStorage.setItem('ids',myArraynoIds)

    //var sus=emergency_ids[ids_count]
    //dispatch(addItem(sus))
    
   // window.localStorage.setItem(1,emergency_ids[ids_count])
   // window.localStorage.setItem("arrayID", window.localStorage.getItem(1))
  }
  else{
    ids_count=1
    sessionStorage.setItem('ids_count', ids_count)

    const myArrayIDs = sessionStorage.getItem('ids');
    const myArray = JSON.parse(myArrayIDs);

    myArray.forEach((item,index)=>{
      noIds.push(item)
    })

    noIds.push(localStorage.getItem(1))
    const myArraynoIds = JSON.stringify(noIds);

    sessionStorage.setItem('ids',myArraynoIds)

   //var sus=emergency_ids[ids_count]
   // dispatch(addItem(sus))
     //window.localStorage.setItem(1,emergency_ids[ids_count])
     //window.localStorage.setItem("arrayID", window.localStorage.getItem(1))
    //${emergency_ids[ids_count]}
  }
  
  closeAd()
  
  forwardWindow.style.display="block"
  document.getElementById("videolist").style.display="none"
  main_video_player.style.pointerEvents='none'

  main_video_player.style.zIndex="1"
  main_video_player.style.position="absolute"
  document.getElementById("player").style.filter="blur(2px)"
  document.getElementById("info").style.filter="blur(2px)"
  document.getElementById("infochannel").style.filter="blur(2px)"
  timerInterval=setInterval(()=>{
    timer--
 
  
  },1000)
 
  
   setTimeout(() => {
    clearInterval(timerInterval)
   
    document.getElementById("btnskipp").style.display="none"
    //здесь загрузка рекламы некорректно как узнать 
   // window.location.assign(`https://youtcheck.annalect.de/youtcheck/${window.localStorage.getItem('vidtype')}/view/?name=${window.localStorage.getItem('name')}&thema=${window.localStorage.getItem('thema')}&s=${window.localStorage.getItem('skipp')}`)
   // попробовать с useNavigate navigate(-1)
    //window.location.assign(`http://localhost:3000/youtcheck/${window.localStorage.getItem('vidtype')}/?name=${window.localStorage.getItem('name')}&thema=${window.localStorage.getItem('thema')}&s=${window.localStorage.getItem('skipp')}`)
    
  }, 3000);
  


  

}



}

  function onPlayerStateChange(event) {

    if (event.data == YT.PlayerState.ENDED) {
     VideoEnd=event.data
    }
  }


}


async function initiate(){
await initYouTubeAPI();
//like=Math.floor(Math.random()*1000);
}

   
   const setAd=()=>{

initiate()
    

var advr = document.getElementById("ad")
var adpan = document.getElementById("adpanel")

document.getElementById("videoplayer").addEventListener('mouseover',function(){
  if(videoOn){
    adpan.style.opacity=1
  }
  else{
    adpan.style.opacity=0
  }
})

document.getElementById("videoplayer").addEventListener('mouseleave',function(){
  if(advr.paused===false && videoOn){
    adpan.style.opacity=0
  }
  else if(advr.paused===true && videoOn){
    adpan.style.opacity=1
  }
  
})



  
    var videoTag=document.getElementById("ad")

    var footerBanner=document.getElementById("footerBanner")
    var closeIcon=document.getElementById("closeIcon")
    
 
    let intervalState
   
   
   
    if(type=="footer"){
     

      setTimeout(() => {
        footerBanner.style.opacity="1"
        closeIcon.style.opacity="1"
      }, 10000);
     
      var adpanel=document.getElementById("adpanel")

   
    adpanel.style.display="none"
      
    }

   


     
      videoTag.style.display="block" 
      const videoStyle=document.querySelector('.Ads')
      videoStyle.style.display="none"
      
      
    var dialog=document.getElementById("iosDialog")
    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
    
     dialog.style.display="block"
     document.body.style.overflow="hidden"
   
 } 

      window.onload=function(){
     
    
        var mainplayer=document.getElementById("player")
        mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      
       current_time_interval=setInterval(()=>{
        var currenttime=playerX.getCurrentTime()
        playerX.onseeked=function(){
          if(!AdEnded && currenttime>=halfDuration){
            SetAds()
          }
        }
        if(currenttime>=halfDuration){
          SetAds()
        }
       
        },1000)

        function SetAds(){
          
            clearInterval(current_time_interval)
            var videoTag=document.getElementById("ad")
        videoTag.style.display="flex"
        mainplayer.style.visibility="hidden"
        if(window.innerWidth<=480 ){
          mainplayer.style.opacity="0"
          mainplayer.style.visibility="hidden"
        }
//if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
        
          
     

        videoOn=true
        videoTag.load()
        videoTag.play()


        time=0;
        videoDuration=0
       const customVideo=document.getElementById("ad");
       const videoTime= document.querySelector('.Timeline')
      
     
       customVideo.addEventListener('timeupdate', function(){
         videoDuration=Math.round(customVideo.duration)
         time=Math.round(customVideo.currentTime)
         
         videoTime.style.width=(time*100)/videoDuration + "%"
   
         if(time>=videoDuration){
           closeAd()
         }
       })

        if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
          document.getElementById("iosloadimg").style.display="block"
          let loadInterval
          loadInterval=setInterval(()=>{
            if(document.getElementById("ad").readyState==4){
              document.getElementById("iosloadimg").style.display="none"
              clearInterval(loadInterval)
            }
          },500)

        }

      
        if(skipp==1){
        intervalState=setInterval(function(){if(videoTag.readyState>2){
          clearInterval(intervalState)
         // document.getElementById("unmute").style.display="block"
          setTimeout(()=>{showSkippBnt()},1000)
        }
      },1000)
      }

      else{
        
      }
      
        mainplayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
          
         }
        
        }
        

    
   document.addEventListener("DOMContentLoaded", ()=>{
   
  /*
  
    time=0;
     videoDuration=0
    const customVideo=document.getElementById("ad");
    const videoTime= document.querySelector('.Timeline')
   
  
    customVideo.addEventListener('timeupdate', function(){
      videoDuration=Math.round(customVideo.duration)
      time=Math.round(customVideo.currentTime)
      
      videoTime.style.width=(time*100)/videoDuration + "%"

      if(time>=videoDuration){
        closeAd()
      }
    })
  
  */
    
  })


  
  }

  
    
    var dislike=Math.floor(Math.random()*1000);
    var paramsString = document.location.search; 
    var searchParams = new URLSearchParams(paramsString);


    useEffect(()=>{

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });

      window.matchMedia("(orientation: landscape)").addEventListener("change",e=>{
        const land=e.matches
      
        if(land){
          document.getElementById("videoplayer").style.marginTop=0
          document.getElementById("ad").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("player").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("shield").style.height=parseInt(document.documentElement.clientHeight-57)+"px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','landscape')
          document.body.style.overflow="hidden"
         
        }
        else{
          document.getElementById("ad").style.height="270px"
          document.getElementById("player").style.height="270px"
          document.getElementById("shield").style.height="212px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','portrait')
          document.body.style.overflow="auto"
        }
      })
    
      
      //document.getElementById("menu").style.display="none"
      //document.getElementById("buttonPanel").style.display="none"
     {setAd()}
     
     if(window.innerWidth<=480){
      document.getElementById('nav').style.display="none"
     }
     else{
      document.getElementById('nav').style.display="block"
     }
    
  
     
  },[])
 
 
  
      return(
          
 
          
          <div className="Videoplayer" id="videoplayer" data-click-tracking="portrait">

    <Dialog onClick={iosStartVideo}/>

    <div className='ForwardWindow' id="forwardWindow">
        <img className='ForwardWindowImg' src={Error}/>
        <h2 className='ForwardWindowText' style={{color:'white'}}>Das Video ist nicht verfügbar. <span style={{color:'red'}}> Klicken Sie auf den Button. </span> Sie werden zürück zur Videoauswahl weitergeleitet </h2>
      
       <Link style={{textDecoration:'none'}} to="/youtcheck/pre/?name=1_yRnF-DyBE3dTCs3c4Iei_FfeJT9AsJz&thema=kuchen&s=1">{<div className='Back'>Zurück zur Auswahl</div>}</Link> 

       
         </div>
       

            <div className="Overlay" onClick={PlayPause} id="shield">
            
          <div className='Adshell' id="adshell">  
          <AdType werbung_typ={type}/>

          <div className='AdPanel' id="adpanel" >
 
          <div className='videoTrack' id='vidTrack'>
       <div className='Timeline'></div>

       </div>
       <Stack spacing={2} id="volumebtn"  direction="row"  alignItems="center">
     
       {isplayed ? <PauseIcon style={{fontSize:25, background:'transparent'}} onClick={handlePlayPause} id="playbtn" className="btnPlay"></PauseIcon>:<PlayArrowIcon style={{fontSize:30, background:'transparent'}} id="pausebtn" onClick={handlePlayPause} className="btnPlay"></PlayArrowIcon>}
      
      
      <SkipNextIcon style={{fontSize:25, background:'transparent'}}/>
    
      <VolumeUp id="volumeicon"/>


      <Slider sx={{'.MuiSlider-thumb':{width:10,height:10, opacity:1}, '.MuiSlider-root':{width:"12%", padding:'0'},'.MuiSlider-rail':{width:"100%"}, backgroundColor: '#FFFFFF'}} id="slider" color="secondary" key={`slider-${value}`} aria-label="Volume"step={0.1} defaultValue={value} min={0} max={1} onChange={handleChangeVolumemore}/>
      
        
        <div className='SomeIcons'>
          <SubtitlesIcon style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <SettingsIcon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          
          <PictureInPictureAltIcon style={{fontSize:20, marginBottom:2, marginRight:20, background:'transparent'}}/>
          <Crop32Icon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <FullscreenIcon  style={{fontSize:25, background:'transparent'}}/>
        </div>
        </Stack>
        
        

       </div> {/*Adpanel*/}


          </div> {/*Adshell*/}


          </div>{/*Shield*/}

{/* 
          <div className='Unmute' id="unmute" onClick={handleUnmute}>
          <p>Video unmuten <br/><span style={{fontSize:14}}>(Click)</span> </p>
         
          </div>*/}
        
        <div className="OverlayCase">
          <div className="Video" id="player"></div>
          <div className='Coverlogo'></div>
          </div>
          
          <div className="BtnSkipp" id="btnskipp" onClick={closeAd} variant='contained'>
          <div className='Countdown'>{countdown}</div>
          <img className='CountdownImg' src={window.localStorage.getItem(7)}/>
          </div>
          
          <div className="VideoInfo" id="info">

          <p className="VideoTitle" id="title" >{window.localStorage.getItem(2)}</p>

          </div>

      
        

          <div className="InfoaboutChannel" id="infochannel">
          <div className='ChannelPanel'>
            <div className='ImgAndAbo'>

              <div className='Container-mobile'>
            
            <div className='Channel-container'><img className="Channel" src={window.localStorage.getItem(7)}></img></div>
           
            <div className="p1">
            <div className='text-container'>{window.localStorage.getItem(3)}</div>
            </div>
            
            </div>
 
            <div className='AboDiv'>

             <div className='AboBtn'>Abonnieren</div>
            
             </div>

             </div>

             <div className='ButtonsPanel'>
             <div className='Btn '><div id="btnlike"><ThumbUpAltOutlinedIcon   sx={{fontSize:18}}> </ThumbUpAltOutlinedIcon> <p style={{marginLeft: "10px"}}>{like}</p></div>
             <div><ThumbDownOffAltOutlinedIcon id="btndislike" style={{fontSize:18}}> </ThumbDownOffAltOutlinedIcon></div></div>
            <div className='Teilen'><ReplyIcon id="btnteilen" style={{transform:'scaleX(-1)',fontSize:18}}></ReplyIcon><p>Teilen</p></div>
           
            <div className='Download'><VerticalAlignBottomOutlinedIcon sx={{marginLeft:'16px',fontSize:18}}></VerticalAlignBottomOutlinedIcon><p>Herunterladen</p></div>
            <div className='Clip'><ContentCutOutlinedIcon sx={{marginLeft:'7px',fontSize:18}}></ContentCutOutlinedIcon><p>Clip</p></div>
            <div className='Save'><QueueOutlinedIcon sx={{marginLeft:'12px',fontSize:18}}></QueueOutlinedIcon><p>Speichern</p></div>
            <div className='Punkte'><p>...</p></div>

            </div>
            </div>
             
                <div className="p3">
                  <div id="Comments">Kommentare</div>
                <div className='Descr'>{window.localStorage.getItem(4)} <span>Mehr</span></div>
                <div className='Comm'><AccountCircleIcon  sx={{fontSize:30, color:'#4487df'}}/> <div id="addCommText">Kommentar hinzufügen</div> </div>
                </div>
              
               
                
            
           
            
            </div>
          


            </div>
      )
      
    
  }



  function iosStartVideo(){
    document.getElementById("iosDialog").style.display="none"
    playerX.playVideo()
 
    document.body.style.overflow="auto"
  }

 

 
 
   function closeFooter(){
    var footerdiv=document.getElementById("FooterDivId")
    footerdiv.style.display="none"
  }
  

  function closeAd(){
    videoOn=false

    let btnApperar=7
    timeafterskipbtn=time-btnApperar
    timeafterskipvid=time

    

    AdEnded=true
    var mainplayer=document.getElementById("player")
    var videoTrack=document.getElementById("vidTrack")
    var playbtn =document.getElementById("playbtn")
    var pausebtn=document.getElementById("pausebtn")
    var btnSkipp=document.getElementById("btnskipp");
    var volumebtn=document.getElementById("volumebtn")
    var adpanel=document.getElementById("adpanel")
    var adshell=document.getElementById("adshell")
   // document.getElementById("unmute").style.display="none"
    videoTrack.style.display="none"
   // playbtn.style.display="none"
    volumebtn.style.display="none"
    adpanel.style.display="none"
    adshell.style.display="none"

    mainplayer.style.opacity="1"
    mainplayer.style.visibility="visible"
    btnSkipp.style.display="none"
    var videoTag=document.getElementById("ad")
    videoTag.style.display="none"
    videoTag.pause()
    clearInterval(current_time_interval)

    mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')

    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    }
    
     
    
      
  

  

    
  
 
 
  if(type=="after"){

    clearInterval(status_interval)
    mainplayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')


   
    
  }
   
    
  }



  function AdType(props){
    const werbung_typ=props.werbung_typ
    if(werbung_typ=="mid"){
      if(window.localStorage.getItem('name')=="1_Wm0ddbQJZDTBax4U29n6DxIhJv3Z7Ot"){

      
      return <><video  poster={Loadi} className="Ads" id="ad" preload="true" playsInline>
          
          <source  src={VideoLong} type="video/mp4"></source>

      
      </video>
      <img className='IosLoadImg' id="iosloadimg" src={Loadi}/>
      </>
      }
    }
    else if(werbung_typ=="footer"){
        return <div className='FooterDiv' id="FooterDivId">

        <img id="footerBanner" className='Footer' src={`https://drive.google.com/uc?export=preview&id=${window.localStorage.getItem('name')}`}/>
        
        <object  onClick={closeFooter} type="imgage/svg+xml" data="close.svg"><img className='CloseIcon' id="closeIcon" src={CLoseIconSvg}/></object>
        </div>
    }

    
    
  } 

 


  



  export default VideoPlayerMid