import React from 'react'
import './styles.scss'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined'; //später ansehen
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'; // verlauf
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'; // sport
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined'; //gaming
import TheatersIcon from '@mui/icons-material/Theaters'; // filme
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'; //nachrichten

import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import StreamOutlinedIcon from '@mui/icons-material/StreamOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';





  function Menu(){

    
        return (
            
            <div className="Menu" id="menu">
             <div className='IconandText' style={{background:'#F2F2F2'}}>
             <HomeOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr'>Startseite</div>
                </div>  


                <div className='IconandText'  >
             <VideoLibraryOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr'>Shorts</div>
                </div>
              

                <div className='IconandText'>
             <SubscriptionsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr'>Abos</div>
                </div>

                <div className='MenuLine'></div>

                <div className='MenuHead'>Mein Youtcheck {`>`}</div>
               
                <div className='IconandText'>
             <HistoryOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr'>Verlauf</div>
                </div>


                <div className='IconandText' >
             <PlaylistPlayOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Playlists</div>
                </div>

             
                <div className='IconandText' >
             <AccessTimeOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr'>Später ansehen</div>
                </div>


                <div className='IconandText' >
             <ThumbUpOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Videos, die ich mag</div>
                </div>

                <div className='MenuLine'></div>

                <div className='MenuHead'>Entdecken {`>`}</div>

                <div className='IconandText' >
             <LocalFireDepartmentOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Trends</div>
                </div>

                <div className='IconandText'>
             <MusicNoteOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Musik</div>
                </div>

                <div className='IconandText'>
             <MovieCreationOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Filme & TV</div>
                </div>
                <div className='IconandText'>
             <StreamOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Live</div>
                </div>
                <div className='IconandText'>
             <SportsEsportsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Gaming</div>
                </div>
                <div className='IconandText'>
             <NewspaperOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Nachrichten</div>
                </div>
                <div className='IconandText'>
             <EmojiEventsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Sport</div>
                </div>
                <div className='IconandText'>
             <EmojiObjectsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Wissen</div>
                </div>
                <div className='IconandText'>
             <PodcastsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Podcasts</div>
                </div>

                <div className='MenuLine'></div>

                  <div className='MenuHead'>Mehr {`>`}</div>

                <div className='IconandText'>
             <SettingsOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Einstellungen</div>
                </div>
                <div className='IconandText'>
             <FlagOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Meldeverlauf</div>
                </div>
                <div className='IconandText'>
             <HelpOutlineOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Hilfe</div>
                </div>
                <div className='IconandText'>
             <FeedbackOutlinedIcon sx={{fontSize:25}} className='IconMenu'/>
             <div className='IconDescr' >Feedback senden</div>
                </div>
           
              

            

             </div>
);
  
}
export default Menu